import React, { useEffect, useRef, useState } from "react";
import WaveSurfer from "wavesurfer.js";
import "./AudioList.css";

const WaveForm = (props) => {
  const waveformRef = useRef(null);
  const wavesurferRef = useRef(null);

  useEffect(() => {
    wavesurferRef.current = WaveSurfer.create({
      container: waveformRef.current,
      waveColor: "#432818",
      progressColor: "#337357",
      height: 128,
    });

    wavesurferRef.current.load(props.file);

    return () => wavesurferRef.current.destroy();
  }, [props.file]);

  const [buttonText, setButtonText] = useState("▶");

  const handlePlayPause = () => {
    if (wavesurferRef.current) {
      wavesurferRef.current.playPause();
    }
    setButtonText((prevText) => (prevText === "▶" ? "❚❚" : "▶"));
  };

  return (
    <div className="inline-container">
      <button className={buttonText === "❚❚" ? "play pause" : "play" } onClick={handlePlayPause}>
        {buttonText}
      </button>
      <div id="waveform" ref={waveformRef}></div>
    </div>
  );
};

export default WaveForm;
